/* eslint-disable */
// email
export function isNumber(item) {
  const numberReg = /^[0-9]+(\.[0-9]{1,2})?$/;
  return numberReg.test(item.trim());
}

// email
export function isEmail(item) {
  const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return emailReg.test(item.trim());
}

// 文本框固定电话校验
export function checkTel(item) {
  const telNoreg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
  return telNoreg.test(item.trim());
}

// 国际版手机号校验
var CONST_AREA_PATTERN_MAP = {
  '886': /^886\d{9}$/, // 台湾
  '852': /^852\d{8}$/, // 香港
  '853': /^853\d{8}/, // 澳门
  '81': /^81\d{10}$/, // 日本
  '82': /^82\d{10}$/, // 韩国
  '65': /^65\d{8}$/, // 新加坡
  '60': /^60\d{9}$/, // 马来西亚
  '62': /^62\d{9}$/, // 印度尼西亚
  '66': /^66\d{9}$/, // 泰国
  '63': /^63\d{10}$/, // 菲律宾
  '855': /^855\d{8}$/, // 吉普寨
  '86': /^861(\d[-\s]?){10}$/, // 中国大陆
  'default': /^1(\d){10}$/, // 其他
}
export function isPhoneByArea(areaCode, phone) {
  const phoneReg = areaCode ? CONST_AREA_PATTERN_MAP[areaCode] : CONST_AREA_PATTERN_MAP['default'];
  let phoneNum = `${areaCode}${phone}`;
  return phoneReg.test(phoneNum.trim());
}


// molbile
export function isPhone(item) {
  const phoneReg = /^1[3456789]\d{9}$/;
  return phoneReg.test(item.trim());
}

// tel
export function isTel(item) {
  const telReg = /^\d{3,4}(-|\s)?\d{3,4}(-|\s)?\d{3,4}$/;
  return telReg.test(item.trim());
}

// tel area
export function isTelArea(item) {
  const telReg = /^\d{3,4}$/;
  return telReg.test(item.trim());
}

// tel NO
export function isTelNO(item) {
  const telReg = /^\d{7,8}$/;
  return telReg.test(item.trim());
}

// fax
export function isFax(item) {
  const faxReg = /^(\d{3,4}-)?\d{7,8}$/;
  return faxReg.test(item.trim());
}

// uri
export function isUrl(item) {
  const urlReg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
  return urlReg.test(item.trim());
}

// image
export function isImg(item) {
  const imgReg = /\.(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)$/;
  return imgReg.test(item);
}

// accept file
export function isAcceptFile(item) {
  const fileReg = /\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|ppt|pptx)$/;
  return fileReg.test(item);
}

// excel file
export function isExcelFile(item) {
  const fileReg = /\.(xls|xlsx)$/;
  return fileReg.test(item);
}

// PDF file
export function isPdfFile(item) {
  const pdfReg = /\.(pdf|PDF|jpg|bmp|gif|jpeg|png)$/;
  return pdfReg.test(item);
}

// 验证QQ号码5-11位
export function isQQ(qq) {
  var qqReg = /^\s*[.0-9]{5,11}\s*$/;
  return qqReg.test(qq);
}

// 验证微信号码5-11位
export function isWX(wx) {
  var wxReg = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
  return wxReg.test(wx);
}

// 银行账号验证
export function isBankcard(item) {
  const bankReg = /^([1-9]{1})(\d{15}|\d{18})$/;
  return bankReg.test(item);
}

// 只能输入整数字
export function isNum(num) {
  const numReg = /^[0-9]+$/;
  return numReg.test(num);
}

// 英文+数字最大20位
export function isAccount(acc) {
  const accReg = /^[a-zA-Z][a-zA-Z0-9_]{1,20}$/;
  return accReg.test(acc);
}

// 英文，数字，字符 6-20位
export function isPassword(pwd) {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/;
  return pwdReg.test(pwd);
}

/* eslint-disable no-new */
import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import queryString from 'query-string'
import { StaticImage } from 'gatsby-plugin-image'
import Provider from 'components/Provider'
import fetch from 'api/fetch'
import { fetchUploadAliOssSts, fetchSurveyUpload } from 'api/index'
import { fetchLogin, fetchSms } from 'api/user'
import Layout from 'layouts/layout'
import SEO from 'components/seo'
import Button, { ButtonProps } from 'components/Base/Button'
import Dialog from 'components/Base/Dialog'
import Header from 'components/Topic/Header'
import Footer from 'components/Topic/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper'
import { isEmail, isPhone } from 'utils/validate'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/swiper-bundle.css'
import { Container } from './index.module.less'

import iconSemicircleRight from 'assets/images/topic/ds2021Integral/icon-semicircle-right.png'
import iconSemicircleLeft from 'assets/images/topic/ds2021Integral/icon-semicircle-left.png'
import iconStar from 'assets/images/topic/ds2021Bug/icon-star.svg'
import methodUserImg from 'assets/images/topic/ds2021Bug/user-image.png'
import methodNotUserImg from 'assets/images/topic/ds2021Bug/not-user-image.png'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const Ds2021Bug: React.FC = () => {
  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    setActiveTab(parsed.tab as string)
  }, [])

  const onTabChange = (value: number) => {
    if (value === 0) {
      setActiveTab('introduce')
    } else if (value === 1) {
      setActiveTab('form')
    }
  }

  return (
    <Layout>
      <Provider>
        <div className={Container}>
          <SEO
            title="神策数据｜全民找BUG｜神策数据驱动大会"
            description="第六届神策数据驱动大会的全民找BUG活动，提交产品BUG获取积分，有好礼相送！"
            keywords="全民找bug,神策数据全民找bug,2021数据驱动大会,神策数据驱动大会"
            saTitle="首页-2021数据驱动大会-全民找BUG"
          />
          <Header />
          <main>
            <Ds2021IntegralBanner />
            <Ds2021BugTab activeTab={activeTab} onTabChange={onTabChange} />
            <Ds2021IntegralPanel title="活动介绍" className="ds2021-bug-introduce hidden-pc">
              <Ds2021BugGuidelines />
            </Ds2021IntegralPanel>
            <Ds2021IntegralPanel title="征集的是什么“BUG”" className="ds2021-bug-type">
              <div className="ds2021-bug-type--left">
                <p>
                  此次活动聚焦三大类产品问题与建议的征集，
                  <br className="hidden-mb" />
                  不涉及到因网络、Demo 环境问题导致的报错和显示问题。
                </p>
                <p>
                  我们将主要从问题影响的深度（痛苦度）和广度（泛用性）两个方面来评估一个 “BUG”
                  的价值，从而评定奖励的等级。
                </p>
              </div>
              <div className="ds2021-bug-type--right">
                <ul>
                  <li>
                    <h3>包含的 BUG 类型</h3>
                    <div className="list">
                      <p>产品功能问题</p>
                      <p>操作或展示的体验问题</p>
                      <p>产品新需求</p>
                    </div>
                  </li>
                  <li>
                    <h3>不包含的 BUG 类型</h3>
                    <div className="list">
                      <p>报错提示</p>
                      <p>页面响应问题</p>
                      <p>网络问题</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Ds2021IntegralPanel>
            <Ds2021IntegralPanel title="参与方式" className="participate-method">
              <img src={iconStar} alt="" className="icon-star hidden-mb" />
              <div className="participate-method--item">
                <h3 className="participate-method--title">
                  我是神策产品的用户{' '}
                  <Button className="tap" btnType="blue" size="small">
                    加分项
                  </Button>
                </h3>
                <div className="participate-method--image">
                  <img src={methodUserImg} alt="" className="method-image" />
                </div>
                <div className="sd-button-group">
                  <Button btnType="blue" href="#tab" onClick={() => setActiveTab('form')}>
                    点击提交
                  </Button>
                </div>
              </div>
              <div className="participate-method--item">
                <h3 className="participate-method--title">我不是神策产品的用户</h3>
                <div className="participate-method--image">
                  <img src={methodNotUserImg} alt="" className="method-image" />
                </div>
                <div className="sd-button-group">
                  <Button btnType="blue" href="/do5thAnniversary" target="_blank">
                    点击体验
                  </Button>
                  <Button btnType="blue" plain href="#tab" onClick={() => setActiveTab('form')}>
                    点击提交
                  </Button>
                </div>
              </div>
            </Ds2021IntegralPanel>
            <Ds2021IntegralPanel title="积分奖励" className="ds2021bug-reward-points">
              <img src={iconStar} alt="" className="icon-star hidden-mb" />
              <img src={iconSemicircleRight} alt="" className="icon-semicircle-right hidden-mb" />
              <img src={iconSemicircleLeft} alt="" className="icon-semicircle-left hidden-mb" />
              <div className="ds2021bug-reward-points--card">
                <StaticImage
                  src="../../../assets/images/topic/ds2021Bug/reward-points-base.png"
                  alt=""
                  className="ds2021bug-reward-points--image"
                />
              </div>
              <div className="ds2021bug-reward-points--card">
                <StaticImage
                  src="../../../assets/images/topic/ds2021Bug/reward-points-extra.png"
                  alt=""
                  className="ds2021bug-reward-points--image"
                />
              </div>
            </Ds2021IntegralPanel>
            <Ds2021IntegralPanel title="活动规则" className="ds2021bug-rule">
              <img src={iconStar} alt="" className="icon-star hidden-mb" />
              <p>
                1. 活动参与者必须按照《神策分析 “BUG” 提交文档》的模板进行 BUG
                提交；每个手机号码可提交多次，每次提交一个文档。
              </p>
              <p>
                2. 活动评委为神策数据 “全民找 BUG” 项目组评审委员；评选标准从 “赋能业务”
                的角度出发，重点关注业务逻辑、业务需求等功能的相关的 “BUG” 。
              </p>
              <p>3. 积分有礼的榜单在 “神策数据” 小程序公布。</p>
              <p>
                4. 有关活动规则的相关问题，请联系 <a href="mailto:mkt@sensorsdata.cn">mkt@sensorsdata.cn</a>。
              </p>
            </Ds2021IntegralPanel>
          </main>
          <Footer />
        </div>
      </Provider>
    </Layout>
  )
}

export default Ds2021Bug

// 积分有礼-banner
const Ds2021IntegralBanner: React.FC = () => {
  return (
    <div className="ds2021-integral-banner">
      <article>
        <div className="ds2021-integral-banner--left">
          <p>积分有礼之</p>
          <h1>全民找 “BUG” 2.0</h1>
          <StaticImage
            src="../../../assets/images/topic/ds2021Integral/banner-subtitle.png"
            alt=""
            // width={isPc() ? 338 : 216}
            className="banner-subtitle"
          />
        </div>
        <div className="ds2021-integral-banner--right">
          <StaticImage src="../../../assets/images/topic/ds2021Bug/banner-icon.png" alt="" className="banner-icon" />
        </div>
        <p className="important-note hidden-pc">
          重要提示：由于活动需完成 “BUG” 文档提交，我们建议您通过 PC
          端访问神策数据官方网站专题页面报名参加，以保证活动体验效果。
        </p>
      </article>
    </div>
  )
}

// 活动指南
const Ds2021BugGuidelines: React.FC = () => {
  return (
    <div className="ds2021-bug-guidelines">
      <img src={iconStar} alt="" className="icon-star hidden-mb" />
      <img src={iconStar} alt="" className="icon-star hidden-mb" />
      <div className="ds2021-bug-guidelines--desc line-1">
        <span className="color-blue">“Best of the Best” 是神策打磨产品的态度！</span>
        <br className="hidden-pc" />
        成长漫漫路上，还有一些“惊喜”（BUG）
        <StaticImage src="../../../assets/images/topic/ds2021Bug/icon-bug.svg" alt="" className="icon-bug" />
        ，它是 “成长印记”，每一个印记背后都是未知的故事，也潜藏着可能的未来。
      </div>
      <div className="ds2021-bug-guidelines--desc line-2">
        <span className="color-blue">如今，在神策 2021 驱动大会之际，我们重启全民找 “BUG” 活动，</span>
        <br className="hidden-mb" />
        诚挚邀请新老朋友，一起探索这些“成长印记”。我们秉承着将事情做到极致的态度，充分吸收朋友们的反馈，持之以恒，不断精进。
      </div>
      <div className="ds2021-bug-guidelines--review hidden-mb">
        <div className="ds2021-bug-review--header">
          <StaticImage src="../../../assets/images/topic/ds2021Bug/review-icon.png" alt="" className="review-icon" />
          <span>往届活动回顾</span>
        </div>
        <h3 className="ds2021-bug-review--title">2020 全民找 “BUG” 1.0</h3>
        <div className="sd-button-group">
          <Button btnType="link" size="small" href="https://mp.weixin.qq.com/s/FU2cpAZ2Vw8v4NQW9H0ODw" target="_blank">
            查看活动详情
            <StaticImage
              src="../../../assets/images/topic/ds2021Bug/icon-arrow-right.png"
              alt=""
              className="icon-arrow-right"
            />
          </Button>
          <Button btnType="link" size="small" href="https://mp.weixin.qq.com/s/eTavrJKEM2j1x6FZEJz0gA" target="_blank">
            查看获奖名单
            <StaticImage
              src="../../../assets/images/topic/ds2021Bug/icon-arrow-right.png"
              alt=""
              className="icon-arrow-right"
            />
          </Button>
        </div>
      </div>
      <div className="ds2021-bug-guidelines--review-mb hidden-pc">
        <StaticImage
          src="../../../assets/images/topic/ds2021Bug/review-icon-mb.png"
          alt=""
          className="review-icon-mb"
        />
        <div className="sd-button-group">
          <Button btnType="link" size="small" href="https://mp.weixin.qq.com/s/FU2cpAZ2Vw8v4NQW9H0ODw" target="_blank">
            查看活动详情
            <StaticImage
              src="../../../assets/images/topic/ds2021Bug/icon-arrow-right.png"
              alt=""
              className="icon-arrow-right"
            />
          </Button>
          <Button btnType="link" size="small" href="https://mp.weixin.qq.com/s/eTavrJKEM2j1x6FZEJz0gA" target="_blank">
            查看获奖名单
            <StaticImage
              src="../../../assets/images/topic/ds2021Bug/icon-arrow-right.png"
              alt=""
              className="icon-arrow-right"
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

interface Ds2021BugTabProps {
  className?: string
  activeTab?: string
  onTabChange?: (value: number) => void
}

// Tab
const Ds2021BugTab: React.FC<Ds2021BugTabProps> = (params) => {
  const { className, activeTab, onTabChange = (value) => {} } = params
  const [current, setCurrent] = useState(0)
  const [privacy, setPrivacy] = useState(false)
  const [visible, setVisible] = useState(false)
  const [progressVisible, setProgressVisible] = useState(false)
  const [progress, setProgress] = useState(0)
  const [fileName, setFileName] = useState<string>()
  const [file, setFile] = useState<any>()
  const [mSwiper, setSwiper] = useState<SwiperCore>()
  const [codeButton, setCodeButton] = useState<string>('获取验证码')
  const [codeLoading, setCodeLoading] = useState<boolean>(false)
  const submitLoading = useRef(false)
  const timer = useRef<any>(null)
  const [errors, setErrors] = useState<any>({
    name: {
      isError: false,
      msg: '',
    },
  })
  const [name, setName] = useState<string>('')
  const [business, setBusiness] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [industry, setIndustry] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
        timer.current = null
      }
    }
  }, [])
  useEffect(() => {
    if (activeTab === 'introduce') {
      onNavChange(0)
    }
    if (activeTab === 'form') {
      onNavChange(1)
    }
    return () => {}
  }, [activeTab])

  const onNavChange = (index: number) => {
    setCurrent(index)
    mSwiper?.slideTo(index)
  }
  const handleChangeFile = (e: any) => {
    setFileName(e.target.files[0].name)
    setFile(e.target.files[0])
    verifyFormData('file', false)
  }

  const handleChangePrivacy = (value: boolean) => {
    setPrivacy(value)
    if (value) {
      verifyFormData('privacy', false)
    }
  }

  // 表单输入
  const handleChangeInput = (key: string, value: string) => {
    if (value) {
      verifyFormData(key, false)
    }
  }

  // 校验表单
  const verifyFormData = (key?: string, isError?: boolean, errorMsg?: string) => {
    let _errors = { ...errors }
    if (key) {
      _errors = {
        ..._errors,
        [key]: {
          isError: isError,
          msg: errorMsg || '',
        },
      }
      if (isError && errorMsg) {
        toast.error(errorMsg, {
          position: 'bottom-right',
          autoClose: 5000,
        })
      }
    } else {
      _errors = {}
      if (!name) {
        _errors = {
          ..._errors,
          name: {
            isError: true,
            msg: '请输入姓名',
          },
        }
      }
      if (!business) {
        _errors = {
          ..._errors,
          business: {
            isError: true,
            msg: '请输入公司名称',
          },
        }
      }
      if (!role) {
        _errors = {
          ..._errors,
          role: {
            isError: true,
            msg: '请选择部门',
          },
        }
      }
      if (!industry) {
        _errors = {
          ..._errors,
          industry: {
            isError: true,
            msg: '请选择行业',
          },
        }
      }
      if (!email || !isEmail(email)) {
        _errors = {
          ..._errors,
          email: {
            isError: true,
            msg: '请输入正确的邮箱',
          },
        }
      }
      if (!phone || !isPhone(phone)) {
        _errors = {
          ..._errors,
          phone: {
            isError: true,
            msg: '请输入正确的手机号',
          },
        }
      }
      if (!code) {
        _errors = {
          ..._errors,
          code: {
            isError: true,
            msg: '请输入验证码',
          },
        }
      }
      if (!file) {
        _errors = {
          ..._errors,
          file: {
            isError: true,
            msg: '请上传文档',
          },
        }
      }
      if (!privacy) {
        _errors = {
          ..._errors,
          privacy: {
            isError: true,
            msg: '隐私协议为必选',
          },
        }
      }
      toastError(_errors)
    }
    setErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const toastError = (errors: any) => {
    const keys = Object.keys(errors)
    keys.forEach((item) => {
      if (errors[item].isError) {
        toast.error(errors[item].msg, {
          position: 'bottom-right',
          autoClose: 5000,
        })
      }
    })
  }

  // 开始倒计时
  const startCodeCountdown = () => {
    let count = 60
    timer.current = setInterval(() => {
      if (count > 0) {
        setCodeButton(`${count--} s重新获取`)
      } else {
        setCodeLoading(false)
        setCodeButton('获取验证码')
        clearInterval(timer.current)
        timer.current = null
      }
    }, 1000)
  }

  // 发送手机验证码
  const getPhoneCode = () => {
    if (!phone || !isPhone(phone)) {
      verifyFormData('phone', true, '请输入正确的手机号')
      return
    }
    if (codeLoading) {
      return
    }
    setCodeLoading(true)
    fetchSms(phone).then((res: any) => {
      let resData = res.data
      if (resData.status === 200) {
        toast.success('验证码发送成功', {
          position: 'bottom-right',
          autoClose: 5000,
        })
        startCodeCountdown()
      } else {
        toast.error(resData.desc, {
          position: 'bottom-right',
          autoClose: 5000,
        })
        setCodeLoading(false)
      }
    })
  }

  // 点击提交
  const getPostParamsBySTS = () => {
    if (submitLoading.current) {
      return
    }
    if (!verifyFormData()) {
      return
    }
    submitLoading.current = true
    fetchUploadAliOssSts({
      real_name: name,
      phone: phone,
      code: code,
    })
      .then((res: any) => {
        let resData = res.data
        if (resData.status !== 200) {
          toast.error(resData.desc, {
            position: 'bottom-right',
            autoClose: 5000,
          })
          submitLoading.current = false
        } else {
          successCallback(resData.data)
        }
      })
      .catch((e) => {
        submitLoading.current = false
        // toast.error(`提交失败,请重试`, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        // });
        // console.log(e);
        // console.log(e.response);
      })
  }

  const successCallback = (data: any) => {
    var formData = new FormData()
    Object.keys(data.formData).forEach((key) => {
      formData.append(key, data.formData[key])
    })
    formData.append('success_action_status', '200') // 上传成功后返回的status， 如果该域的值未设置或者设置成一个非法值，OSS返回一个空文档和204状态码
    var file_name = data.file_name + file.name.slice(file.name.lastIndexOf('.'))
    formData.append('key', file_name)
    formData.append('file', file) // 文件或文件内容必须是表单最后一个域

    formData.append('real_name', name)
    formData.append('company_name', business)
    formData.append('job_title', role)
    formData.append('industry', industry)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('code', code)
    // 展示进度条
    setProgressVisible(true)
    fetch({
      url: data.url,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setProgress(((progressEvent.loaded / progressEvent.total) * 100) | 0)
      },
    })
      .then((res: any) => {
        if (res.status === 200) {
          submitUserInfo(formData)
        } else {
          setProgressVisible(false)
          submitLoading.current = false
          toast.error(`文件上传失败, ${res.statusText}`, {
            position: 'bottom-right',
            autoClose: 5000,
          })
        }
      })
      .catch((e) => {
        setProgressVisible(false)
        submitLoading.current = false
        toast.error(`文件上传失败, ${e.message}`, {
          position: 'bottom-right',
          autoClose: 5000,
        })
      })
  }

  const submitUserInfo = (formData: FormData) => {
    fetchSurveyUpload({
      survey_file_name: formData.get('key'),
      real_name: name,
      company_name: business,
      job_title: role,
      industry: industry,
      email: email,
      phone: phone,
      code: code,
    })
      .then((res) => {
        let resData = res.data
        submitLoading.current = false
        setProgressVisible(false)
        if (resData.status === 200) {
          setVisible(true)
        } else {
          toast.error(`提交失败,请重试`, {
            position: 'bottom-right',
            autoClose: 5000,
          })
        }
      })
      .catch((e) => {
        submitLoading.current = false
        setProgressVisible(false)
        console.log(e)
      })

    fetchLogin({
      code: code || '',
      user_name: name || '',
      group_name: business || '',
      industry: industry || '',
      department: role || '',
      email: email || '',
      phone: phone || '',
      channel: 'report_bug' || '',
    })
      .then((res) => {
        let resData = res.data
        if (resData.status === 200) {
          // toast.success("线索录入失败");
        } else {
          // toast.error("线索录入失败");
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div id="tab" className="ds2021-bug-tab hidden-mb">
      <img src={iconSemicircleRight} alt="" className="icon-semicircle-right hidden-mb" />
      <img src={iconSemicircleLeft} alt="" className="icon-semicircle-left hidden-mb" />
      <article>
        <ul>
          <li className={classnames({ active: current === 0 })} onClick={() => onNavChange(0)}>
            活动指南
          </li>
          <li className={classnames({ active: current === 1 })} onClick={() => onNavChange(1)}>
            提交 “BUG”
          </li>
        </ul>
        <Swiper
          className={classnames('ds2021-bug-tab--content')}
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => {
            onTabChange(swiper.activeIndex as number)
          }}
          allowTouchMove={false}
          autoHeight={true}
        >
          <SwiperSlide>
            <Ds2021BugGuidelines />
          </SwiperSlide>
          <SwiperSlide className="ds2021-bug-form">
            <div className="ds2021-bug-form--item ds2021-bug-form--left">
              <h3 className="ds2021-bug-form--title">步骤一</h3>
              <p className="ds2021-bug-form--desc">
                1. 为确保您能拿到大奖，请填写以下信息（注意：请保证以下信息真实有效）
              </p>
              <div className="ds2021-bug-form--body subForm">
                <div className="subFItem">
                  <div className="subFItemTitle">*姓名：</div>
                  <input
                    className={classnames('subFItemInput', { error: errors['name']?.isError })}
                    maxLength={20}
                    type="text"
                    name=""
                    id="commit_name"
                    placeholder="请输入姓名"
                    onChange={(e) => {
                      handleChangeInput('name', e.target.value)
                      setName(e.target.value)
                    }}
                  />
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*公司名称：</div>
                  <input
                    className={classnames('subFItemInput', { error: errors['business']?.isError })}
                    maxLength={50}
                    type="text"
                    name=""
                    id="commit_business"
                    placeholder="请输入公司名称"
                    onChange={(e) => {
                      handleChangeInput('business', e.target.value)
                      setBusiness(e.target.value)
                    }}
                  />
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*部门：</div>
                  <select
                    className={classnames('subFItemInput subFItemSelect', { error: errors['role']?.isError })}
                    placeholder="请选择部门"
                    name=""
                    id="commit_role"
                    onChange={(e) => {
                      handleChangeInput('role', e.target.value)
                      setRole(e.target.value)
                    }}
                  >
                    <option className="part_sel_option" value="">
                      请选择部门
                    </option>
                    <option className="part_sel_option" value="产品">
                      产品
                    </option>
                    <option className="part_sel_option" value="运营">
                      运营
                    </option>
                    <option className="part_sel_option" value="市场">
                      市场
                    </option>
                    <option className="part_sel_option" value="数据">
                      数据
                    </option>
                    <option className="part_sel_option" value="技术">
                      技术
                    </option>
                    <option className="part_sel_option" value="其他">
                      其他
                    </option>
                  </select>
                  <span className="subFItemSelect-iconArrow"></span>
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*行业：</div>
                  <select
                    className={classnames('subFItemInput subFItemSelect', { error: errors['industry']?.isError })}
                    placeholder="请选择行业"
                    name=""
                    id="commit_industry"
                    onChange={(e) => {
                      handleChangeInput('industry', e.target.value)
                      setIndustry(e.target.value)
                    }}
                  >
                    <option className="part_sel_option" value="">
                      请选择行业
                    </option>
                    <option className="part_sel_option" value="工具软件">
                      工具软件
                    </option>
                    <option className="part_sel_option" value="证券基金">
                      证券基金
                    </option>
                    <option className="part_sel_option" value="社交网络">
                      社交网络
                    </option>
                    <option className="part_sel_option" value="在线旅游">
                      在线旅游
                    </option>
                    <option className="part_sel_option" value="医疗健康">
                      医疗健康
                    </option>
                    <option className="part_sel_option" value="在线文娱">
                      在线文娱
                    </option>
                    <option className="part_sel_option" value="在线教育">
                      在线教育
                    </option>
                    <option className="part_sel_option" value="融合媒体（广播、电视、报纸、IPTV/OTT等）">
                      融合媒体（广播、电视、报纸、IPTV/OTT等）
                    </option>
                    <option className="part_sel_option" value="银行保险">
                      银行保险
                    </option>
                    <option className="part_sel_option" value="互联网金融">
                      互联网金融
                    </option>
                    <option className="part_sel_option" value="本地生活">
                      本地生活
                    </option>
                    <option className="part_sel_option" value="房产服务">
                      房产服务
                    </option>
                    <option className="part_sel_option" value="电子商务">
                      电子商务
                    </option>
                    <option className="part_sel_option" value="品牌零售">
                      品牌零售
                    </option>
                    <option className="part_sel_option" value="企业服务">
                      企业服务
                    </option>
                    <option className="part_sel_option" value="汽车交通">
                      汽车交通
                    </option>
                    <option className="part_sel_option" value="电信运营商">
                      电信运营商
                    </option>
                    <option className="part_sel_option" value="其他">
                      其他
                    </option>
                  </select>
                  <span className="subFItemSelect-iconArrow"></span>
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*邮箱地址：</div>
                  <input
                    className={classnames('subFItemInput', { error: errors['email']?.isError })}
                    type="text"
                    name=""
                    id="commit_email"
                    placeholder="请输入邮箱地址"
                    onChange={(e) => {
                      handleChangeInput('email', e.target.value)
                      setEmail(e.target.value)
                    }}
                  />
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*手机号码：</div>
                  <input
                    className={classnames('subFItemInput', { error: errors['phone']?.isError })}
                    maxLength={11}
                    type="text"
                    name=""
                    id="commit_phone"
                    placeholder="请输入手机号码"
                    onChange={(e) => {
                      handleChangeInput('phone', e.target.value)
                      setPhone(e.target.value)
                    }}
                  />
                  <span className="subFItemGetCode" onClick={() => getPhoneCode()}>
                    {codeButton}
                  </span>
                </div>
                <div className="subFItem">
                  <div className="subFItemTitle">*验证码：</div>
                  <input
                    className={classnames('subFItemInput', { error: errors['code']?.isError })}
                    maxLength={4}
                    type="text"
                    name=""
                    id="commit_code"
                    placeholder="请输入验证码"
                    onChange={(e) => {
                      handleChangeInput('code', e.target.value)
                      setCode(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ds2021-bug-form--arrow">
              <img
                src="https://sensorsdata.cn/assets/img/topic/point_94d9bd9.png"
                alt=""
                className="form-center-arrow"
              />
            </div>
            <div className="ds2021-bug-form--item ds2021-bug-form--right">
              <h3 className="ds2021-bug-form--title">步骤二</h3>
              <p className="ds2021-bug-form--desc">
                2. 为确保您能拿到大奖，请填写以下信息（注意：请保证以下信息真实有效）
              </p>
              <div className="ds2021-bug-form--body fileSub">
                <div className="fileDown">
                  <div className="subFItemTitle">模板下载：</div>
                  <a
                    className="fileDownButton"
                    href="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/topic/ds2021/%E7%A5%9E%E7%AD%96%E4%BA%A7%E5%93%81%E2%80%9CBUG%E2%80%9D%E6%8F%90%E4%BA%A4%E6%96%87%E6%A1%A3%E6%A8%A1%E6%9D%BF-%E5%B0%8F%E6%95%B0%E7%82%B9.docx"
                    target="_blank"
                  >
                    下载模板
                  </a>
                  <img className="fileDownImg" src="https://sensorsdata.cn/assets/img/topic/word_7e17195.png" alt="" />
                  <p className="fileName">神策分析 BUG 提交文档模板.docx</p>
                </div>
                <p className="fileDownTips">（注意：请先下载模板，按照模板的内容进行填写）</p>
                <div className="fileDown fileUp">
                  <div className="subFItemTitle">上传文档：</div>
                  <div className={classnames('fileDownButton', { error: errors['file']?.isError })}>
                    上传文档
                    <input
                      className="fileDownButtonInput error"
                      id="commit_file"
                      type="file"
                      accept=".doc,.docx"
                      onChange={(e) => handleChangeFile(e)}
                    ></input>
                  </div>
                  <p className="fileName">{fileName}</p>
                </div>
                <p className="fileDownTips">
                  （注：命名规则 “神策分析 BUG 提交文档-姓名”，文档每次最多上传 1 个，文档大小在 20M 以内）
                </p>
                <div className={classnames('fileRule', { error: errors['privacy']?.isError })}>
                  <img
                    className="fileRuleAg"
                    src="https://sensorsdata.cn/assets/img/topic/agreeNo_eb82577.png"
                    alt=""
                    style={{ display: !privacy ? 'inline-block' : 'none' }}
                    onClick={() => handleChangePrivacy(true)}
                  />
                  <img
                    className="fileRuleAg"
                    src="https://sensorsdata.cn/assets/img/topic/agree_2accd0a.png"
                    alt=""
                    style={{ display: privacy ? 'inline-block' : 'none' }}
                    onClick={() => handleChangePrivacy(false)}
                  />
                  <div className="fileRuleText">
                    我已阅读并同意{' '}
                    <a className="fileRuleT" href="https://www.sensorsdata.cn/compliance/privacy.html" target="_blank">
                      《隐私条款》
                    </a>
                  </div>
                </div>
                <Button btnType="blue" className="fileBButton" onClick={() => getPostParamsBySTS()}>
                  提交
                </Button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </article>
      <Dialog
        className="ds2021-bug-commit-dialog"
        visible={visible}
        onClose={() => setVisible(false)}
        width={620}
        hideFooter
      >
        <div className="ds2021-bug-commit-dialog-content">
          <StaticImage src="../../../assets/images/topic/ds2021Bug/icon-success.png" alt="" className="icon-success" />
          <p className="success-desc">您的“BUG”文档已经提交至活动评审组！感谢您的参与！</p>
          <p className="qrcode-desc">扫码下方二维码，查看积分榜</p>
          <StaticImage
            src="../../../assets/images/topic/ds2021Integral/qrcode-jifenbang.png"
            alt=""
            className="image-qrcode"
          />
          <p className="commit-success-info">注意：倘若提交了文档没有看到积分，请不要着急，我们的积分榜按周更新哦！</p>
        </div>
      </Dialog>
      {/* 进度条 */}
      <Dialog visible={progressVisible} width={690} hideFooter={true} className="ds2021-bug-commit-progress-dialog">
        <div className="mongoliaContent">
          <div className="mongoliaContentT">您的" BUG "文档正在提交</div>
          <div className="barContent">
            <div className="bar" id="progress_inner2" style={{ width: progress + '%' }}></div>
          </div>
        </div>
      </Dialog>
      <ToastContainer />
    </div>
  )
}

interface Ds2021IntegralPanelProps {
  className?: string
  title?: string
}
// 积分有礼-panel
const Ds2021IntegralPanel: React.FC<Ds2021IntegralPanelProps> = (params) => {
  const { className, title, children } = params
  return (
    <div className={classnames('ds2021-integral-panel', className)}>
      <div className="ds2021-integral-panel--header">
        <h2>{title}</h2>
        <StaticImage
          src="../../../assets/images/topic/ds2021Integral/icon-rectangle.png"
          alt=""
          className="line hidden-mb"
        />
        <StaticImage
          src="../../../assets/images/topic/ds2021Integral/icon-rectangle-mb.png"
          alt=""
          className="line hidden-pc"
        />
      </div>
      <div className="ds2021-integral-panel--body">
        <article>{children}</article>
      </div>
    </div>
  )
}
